const content = {
  title: 'Ratgeber - Betriebliche Altersvorsorge',
  headvisualHeadline: 'Betriebliche Altersvorsorge für Geschäftsführer.',
  firstParagraphs: [
    'Gerade Sie als Gesellschafter - Geschäftsführer (GGF) müssen ihre Altersvorsorge aktiv in die Hand nehmen. Ein Arbeitnehmer ist durch die Beiträge in die gesetzliche Rentenversicherung für das Alter abgesichert. Doch bei ihnen als GGF klafft hier ein großes Defizit. Denn mit der Entscheidung, Verantwortung für ein Unternehmen zu übernehmen, geht häufig auch die Entscheidung einher, keine Beiträge mehr in die gesetzliche Rentenversicherung zu zahlen.',
    'Dabei ist doch gerade für Sie als GGF eine angemessene Altersvorsorge so wichtig. Denn nahezu alle GGFs haben das folgende gemeinsam: Die berufliche Belastung ist stark, ihr Engagement ist hoch und ihr Lebensstandard gehoben. Vor allem das letzte sollte im Ruhestand auch so bleiben. Aus diesem Grund ist es so wichtig frühzeitig mit dem Aufbau einer angemessenen Altersvorsorge zu beginnen.'
  ],
  sectionHead: {
    headline:
      'Heute Geschäftsführer - morgen entspannter Rentner - in nur 3 Schritten.',
    paragraph:
      'Um eine angemessene betriebliche Altersversorgung aufzubauen, sollte die Vorsorge in drei  Schritten erfolgen:'
  },
  secondParagraph:
    'Als ersten Schritt richten Sie eine steuerlich interessante Rentenversicherung im Rahmen einer Entgeltumwandlung über die Direktversicherung ein. Bei einer Entgeltumwandlung verzichten Sie auf einen Teil Ihres Bruttogehalts. Dieser Gehaltsanteil wird dann in ein für Sie individuell passendes Vorsorgeprodukt investiert. Für die Entgeltumwandlung gelten dabei die steuerlichen Höchstgrenzen von 4 % (Sozialversicherung) bzw. 8 % (Steuer) der Beitragsbemessungsgrenze. Ist die Grenze ausgeschöpft erfolgt der 2. Schritt.',
  dialog: {
    buttonText: 'Mehr anzeigen',
    buttonVariant: 'gray-blue',
    title: 'Heute Geschäftsführer - morgen entspannter Rentner.',
    paragraphs: [
      'Da häufig die steuerlichen Freigrenzen in Schritt 1 nicht ausreichen um eine Altersvorsorge aufzubauen, die dem gehobenen Lebensstandard eines GGF entsprechen, wird im zweiten Schritt die Altersvorsorge durch einen Beitrag in einen Tarif im Durchführungsweg der Unterstützungskasse aufgebaut. Da GGF oft von der Sozialversicherung befreit sind, sind den Höhen der Beiträge nur geringe Grenzen gesetzt (siehe Abschnitt Steuerliche Besonderheiten). Da die Beiträge in eine Unterstützungskasse aber laufend sein müssen, muss für Einmalzahlungen wie z. B. Tantiemen eine andere Lösung gefunden werden. Daher folgt nun Schritt 3.',
      'Gerade GGF erhalten häufig Tantiemen oder sonstige Sonderzahlungen (nicht genommener Urlaub, Weihnachtsgeld usw.). Da es sich hier um zusätzliches Einkommen handelt welches somit nicht zur Deckung des täglichen Bedarfs benötigt wird, sollten diese Zahlungen in den Aufbau einer Betriebsrente als 3. Schritt des Aufbaus einer angemessenen betrieblichen Rente investiert werden. Dabei wird die Sonderzahlung brutto als Beitrag in ein Rentenprodukt bezahlt und durch eine beitragsorientierten Leistungszusage in eine Rentenzusage umgewandelt. Der gezahlte Beitrag wird nicht versteuert, erst die im Rentenbezug gezahlt Rente. Somit erhält man einen positiven Steuereffekt.',
      'Weitere Vorteile sind zudem:'
    ],
    listItems: [
      'Für jede Sonderzahlung kann erneut entschieden werden, ob diese zugunsten einer Betriebsrente investiert werden soll.',
      'Für die Sonderzahlungen müssen keine Rückstellungen gebildet werden. Die Umwandlung der Zahlungen geschieht somit bilanzneutral.',
      'Durch die Gestaltung als beitragsorientierte Leistungszusage besteht ein geringes Haftungsrisiko für den Arbeitgeber.'
    ]
  },
  thirdParagraphs: [
    'Befolgen Sie diese 3 Schritte, stellt der Aufbau einer angemessenen Altersvorsorge für Sie als GGF kaum ein Problem dar.',
    '<strong>Jedoch gibt es doch noch seitens des Finanzamts etwas zu beachten:<br>Steuerliche Besonderheiten für die betriebliche Altersversorgung eines Geschäftsführers.</strong>',
    'Da der Gesellschafter - Geschäftsführer (GGF) in seinem Unternehmen einen Sonderstatus hat, wird für die steuerliche Anerkennung der Versorgungszusage durch die Finanzbehörde einiges vorausgesetzt.',
    'Grundsätzlich ist ausschlaggebend, ob Sie als GGF beherrschend oder nicht beherrschend sind und ob es sich um eine Kapital- oder Personengesellschaft handelt. Von einem Status als beherrschender GGF kann ausgegangen werden, wenn Sie z.B. die alleinige Leitungsmacht ausüben. Ist dies der Fall, dann müssen die folgenden Kriterien für die Erteilung einer Rentenzusage beachten werden:'
  ],
  kriterien: {
    headline: 'Kriterien für die Erteilung einer Rentenzusage:',
    listItems: [
      'Sie als GGF müssen sich im Sinne einer Probezeit bewährt haben. Diese beträgt mindestens 2 bis 3 Jahre in denen Sie für ihre Firma bereits tätig sein müssen.',
      'Die Rentenleistungen müssen klar und eindeutig sein und schriftlich fixiert werden.',
      'Auch das Unternehmen selbst muss eine Art „Probezeit“ bestanden haben und mindestens 5 Jahre bestehen.',
      'Sie als GGF müssen vom Selbstkontrahierungsverbot nach § 181 BGB befreit sein.',
      'Das Gehalt, welches Sie sich als GGF bezahlen sowie die Rentenleistungen, die Sie sich zusagen, müssen angemessen von der Höhe sein (die Rentenhöhe darf nicht mehr als 75 % des letzten Aktivengehaltes betragen).',
      'Die künftige Rentenleistung muss für Sie als GGF erdienbar sein, was bedeutet, dass zwischen der Erteilung der Rentenzusage und dem möglichen Renteneintritt mindestens 10 Jahre liegen müssen. Sind Sie hingegen ein nicht beherrschender GGF, müssen Sie weniger harte Voraussetzungen als ein beherrschender GGF erfüllen.'
    ]
  },
  closure: {
    headline: 'Seien Sie es sich wert!',
    paragraph:
      'Das liest sich alles sehr theoretisch? Dann kommen Sie auf uns zu! Ihre Rente ist unsere Leidenschaft. Und Rentenberatung unsere Spezialität.'
  }
};

export default content;
