import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'src/layout/MainLayout';
import {
  ContentBox,
  List,
  Paragraphs,
  SectionHead,
  NonStretchedImage,
  Dialog
} from 'src/components';

import content from 'src/content/aktuelles/ratgeber/betriebliche-altersvorsorge';

const RatgeberBetrieblicheAltersvorsorgePage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: "75"
    }}
  >
    <section>
      <div className="container container--simple children-margin">
        <Paragraphs items={content.firstParagraphs} />
      </div>
    </section>
    <section>
      <div className="container container--simple children-margin">
        <SectionHead headline={content.sectionHead.headline} centered>
          <p>{content.sectionHead.paragraph}</p>
        </SectionHead>
        <NonStretchedImage fluid={data.dreiSchritte.childImageSharp.fluid} />
        <p>{content.secondParagraph}</p>
        <Dialog
          buttonText={content.dialog.buttonText}
          buttonVariant={content.dialog.buttonVariant}
          title={content.dialog.title}
        >
          <Paragraphs items={content.dialog.paragraphs} />
          <ul>
            {content.dialog.listItems.map((listItem, index) => (
              <li key={index}>{listItem}</li>
            ))}
          </ul>
        </Dialog>
        <Paragraphs items={content.thirdParagraphs} innerHTML />
      </div>
    </section>
    <section>
      <ContentBox
        background="light-blue"
        headline={content.kriterien.headline}
        single
      >
        <List items={content.kriterien.listItems} columns={2} />
      </ContentBox>
      <ContentBox
        background="light-salmon"
        headline={content.closure.headline}
        single
      >
        <p className="text-2xl">{content.closure.paragraph}</p>
      </ContentBox>
    </section>
  </MainLayout>
);

export default RatgeberBetrieblicheAltersvorsorgePage;

export const query = graphql`
    {
        headvisual: file(
            sourceInstanceName: { eq: "images" }
            relativePath: { eq: "man-grey-haired-looks-upwards.jpg" }
        ) {
            ...image
        }
        dreiSchritte: file(
            sourceInstanceName: { eq: "illustrations" }
            relativePath: { eq: "betriebliche-altersvorsorge-drei-schritte.png" }
        ) {
            ...image
        }
    }
`;
